define('bcf/components/responsive-image', ['exports', 'bcf/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    rootURL: _environment.default.rootURL,
    actions: {}
  });
});