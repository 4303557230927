define('bcf/controllers/fields/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },


    actions: {
      updateState: function updateState(link) {
        this.set('currentRoute', link.id);
      }
    }
  });
});