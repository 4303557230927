define("bcf/routes/fields", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var links = [{
        id: "fields-data",
        type: "fields",
        attributes: {
          title: "Field Data"
        }
      }, {
        id: "select-post-types",
        type: "fields",
        attributes: {
          title: "Select Post Type(s)"
        }
      }, {
        id: "select-taxonomies",
        type: "fields",
        attributes: {
          title: "Select Taxonomies"
        }
      }, {
        id: "select-pages",
        type: "fields",
        attributes: {
          title: "Select Pages"
        }
      }, {
        id: "select-metabox",
        type: "fields",
        attributes: {
          title: "Select MetaBox"
        }
      }];
      return links;
    }
  });
});