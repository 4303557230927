define('bcf/router', ['exports', 'bcf/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    this.route('/');
    this.route('fields', function () {
      this.route('fields-data');
      this.route('select-post-types');
      this.route('select-taxonomies');
      this.route('select-pages');
      this.route('select-metabox');
    });
    this.route('metaboxes');
    this.route('configuration');
    this.route('about-me');
    this.route('support');
    this.route('contact');
    this.route('miscellaneous');
  });

  exports.default = Router;
});