define('bcf/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var routesWithoutSidebar = ["about-me", "contact", "support"];
  var docLinks = [{
    route: 'index',
    title: 'Getting Started'
  }, {
    route: 'fields.index',
    title: 'Fields Tab',
    class: 'has-child',
    links: [{
      route: "fields.fields-data",
      title: "Field Data"
    }, {
      route: "fields.select-post-types",
      title: "Select Post Type(s)"
    }, {
      route: "fields.select-taxonomies",
      title: "Select Taxonomies"
    }, {
      route: "fields.select-pages",
      title: "Select Page(s)"
    }, {
      route: "fields.select-metabox",
      title: "Select Meta Box"
    }]
  }, {
    route: 'metaboxes',
    title: "Meta Boxes Tab"
  }, {
    route: 'configuration',
    title: "Configuration Tab"
  }, {
    route: 'miscellaneous',
    title: "Miscellaneous"
  }];

  exports.default = Ember.Controller.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },

    links_states: Ember.computed('currentPath', function () {
      var links_states = {},
          links_default_states = {
        '': 'inactive',
        'about-me': 'inactive',
        'contact': 'inactive',
        'support': 'inactive'
      };
      for (var key in links_default_states) {
        links_states[key] = this.currentPath == key ? 'active' : 'inactive';
      }
      console.log(this.currentPath);
      return links_states;
    }),
    links: Ember.computed('currentPath', function () {
      var this_obj = this;
      function add_state(links) {
        links.slice().forEach(function (link, i) {
          var state = this_obj.currentPath == link.route || this_obj.currentPath.split('.')[0] + '.index' == link.route ? 'active' : 'inactive';
          Ember.set(links[i], 'state', state);
          if (link.links) {
            add_state(link.links);
          }
        });
        return links;
      }
      var docLinkswithState = add_state(docLinks);
      console.log(docLinkswithState);
      return add_state(docLinks);
    }),
    isSidebarVisible: Ember.computed('currentPath', function () {
      return Ember.$.inArray(this.currentPath, routesWithoutSidebar) == -1;
    }),
    date: Ember.computed(function () {
      var d = new Date();
      return d.getFullYear();
    }),
    isNotOnIndexPage: Ember.computed('currentPath', function () {
      return this.currentPath != 'index';
    }),
    actions: {}
  });
});