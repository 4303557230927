define('bcf/controllers/fields', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var _utils = {};

  exports.default = Ember.Controller.extend({
    currentRoute: null,
    init: function init() {
      this._super.apply(this, arguments);
    },

    linksStates: Ember.computed('currentRoute', 'target', function () {
      var _links_default_states;

      var links_states = {},
          links_default_states = (_links_default_states = {
        'fields-data': 'active',
        'select-post-types': 'inactive'
      }, _defineProperty(_links_default_states, 'select-post-types', 'inactive'), _defineProperty(_links_default_states, 'select-taxonomies', 'inactive'), _defineProperty(_links_default_states, 'select-pages', 'inactive'), _defineProperty(_links_default_states, 'select-metabox', 'inactive'), _links_default_states);
      var current_path = this.get('currentRoute') || this.get('target').currentPath;
      for (var key in links_default_states) {
        links_states[key] = current_path == 'fields.' + key ? 'active' : 'inactive';
      }
      return links_states;
    }),
    links: Ember.computed('linksStates', function () {
      var _this = this;

      var links_with_state = [],
          links = [{
        id: "fields-data",
        type: "fields",
        attributes: {
          title: "Field Data"
        }
      }, {
        id: "select-post-types",
        type: "fields",
        attributes: {
          title: "Select Post Type(s)"
        }
      }, {
        id: "select-taxonomies",
        type: "fields",
        attributes: {
          title: "Select Taxonomies"
        }
      }, {
        id: "select-pages",
        type: "fields",
        attributes: {
          title: "Select Pages"
        }
      }, {
        id: "select-metabox",
        type: "fields",
        attributes: {
          title: "Select MetaBox"
        }
      }];
      links.slice().forEach(function (link, i) {
        var original_id = link.id;
        links_with_state[i] = link;
        links_with_state[i].id = "fields." + link.id;
        links_with_state[i].state = _this.get('linksStates')[original_id];
      });
      return links_with_state;
    }),

    actions: {
      updateState: function updateState(link) {
        this.set('currentRoute', link.id);
      }
    }
  });
});